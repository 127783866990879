import React from 'react'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import './App.scss'
import './styles/typography.scss'


import Home from './components/home'
import BracCaseStudy from './components/bracCaseStudy'






const App = () => {

  

    return (
      <Router>
        <div className='app-container'>
          <Switch>
            <Route path='/' exact component={Home}/>
            <Route path='/brac-case-study' exact component={BracCaseStudy}/>
        </Switch>
        </div>
      </Router>
    )
}

export default App
