import React from 'react'
import { Link } from 'react-scroll'
import '../components/navbar.scss'
import { useMediaQuery } from '@material-ui/core'

import LinkedIn from '../images/LinkedIn-Logo.wine 1.png'
import GitHub from '../images/github-logo-white.png'
import LinkedInSm from '../images/LinkedIn-Icon-Logo.small.svg'
import HamburgerMenu from '../images/navbar-Hamburger.svg'
import CloseX from '../images/svg/close_big-red.svg'


const CaseStudyNav = (props) => {

    const changeLogo = useMediaQuery('(min-width: 1645.324px)')

    const viewLinks = useMediaQuery('(min-width: 1001.324px)')


    const { slideNav, setSlideNav, showSiteOverlay, setShowSiteOverlay, closeNav } = props


    const hamburgerClick = e => {
        setSlideNav(!slideNav)
        setShowSiteOverlay(!showSiteOverlay)
    }

    return(

        <div className='navbar'>
        {
            viewLinks ? (
                <>
                    <Link
                    activeClass='active'
                    to='section1'
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                    className='aTag navbar__link1'><span className='code-text c1'>&#60;</span><span className='code-text c2'>div</span> <span className='code-text c3'>id</span><span className='code-text c2'>&#61;</span><span className='code-text c4'>'Adam-Riles / Home'</span>
                    </Link>
                </>
            )
            :
            <Link
            activeClass='active'
            to='section1'
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            className='aTag navbar__link1'><span className='code-text c1'>&#60;</span><span className='code-text c2'>div</span> <span className='code-text c3'>id</span><span className='code-text c2'>&#61;</span><span className='code-text c4'>'Adam-Riles'</span><span className='code-text c1'>&#62;</span>
            <span className='code-text c1'>&#60;</span><span className='c1'>&#47;</span><span className='code-text c2'>div</span><span className='c1'>&#62;</span>
            </Link>
        }

        {
            viewLinks ? (
                <>
                    <Link
                    activeClass='active'
                    to='research'
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                    className='aTag navbar__link2'><span className='code-text c3'>Research</span></Link>
                    <Link
                    activeClass='active'
                    to='themes'
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                    className='aTag navbar__link3'><span className='code-text c3'>Themes</span></Link>
                    <Link
                    activeClass='active'
                    to='final'
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                    className='aTag navbar__link4'><nobr><span className='code-text c3'>Final</span></nobr><span className='code-text c1'>&#62;</span></Link>

                    <a  href= 'https://www.linkedin.com/in/adam-riles-851145199/' className='navbar__link5'>
                        { changeLogo ? <img src={LinkedIn} alt='LinkedIn' className='linkedInLogo'/> : <img src={LinkedInSm} alt='LinkedIn' className='linkedInLogoSm'/>}
                    </a>
                    <a href= 'https://github.com/LordUptown/bronxRiverArt.git' className='navbar__link6'><img src={GitHub} alt='GitHub' className='gitHubLogo'/></a>

                    <p className='aTag navbar__link7'><span className='code-text c1'>&#60;</span><span className='c1'>&#47;</span><span className='code-text c2'>div</span><span className='c1'>&#62;</span>
                    </p>
                </>
            )
            :
            <>
                { slideNav ? <img className='closeX' src={CloseX} onClick={hamburgerClick, closeNav}/> : <img className='hamburger-menu' src={HamburgerMenu} onClick={hamburgerClick}/>}
            </>
        }

        </div>
    )
}


export default CaseStudyNav
