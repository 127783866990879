import React from 'react'
import '../components/section4.scss'

import Contact from '../components/contactForm'


const Section4 = () => {
    return(
        <div id='section4' className='section4-container'>
            <div className="yellowLine"></div>
            <div className='white-background'>
                <div className='white-background__border-line'></div>
                <div className='white-background__paper-line1'></div>
                <div className='white-background__paper-line2'></div>
                <div className='white-background__paper-line3'></div>
                <div className='white-background__paper-line4'></div>
                <div className='white-background__paper-line5'></div>
                <div className='white-background__paper-line6'></div>
            </div>
            <Contact />
        </div>
    )
}

export default Section4
