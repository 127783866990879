import React, { useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'

import '../components/section3.scss'


import Portfolio from '../images/portfolioSite.jpg'
import OldBrac from '../images/BracOldSite.jpg'
import NewBrac from '../images/BracNewSite.jpg'
import OldDominicks from '../images/Dominicks.jpg'
import NewDominicks from '../images/NewDominicks.jpg'
import OldBCM from '../images/oldbcmmedia.jpg'
import NewBCM from '../images/BCM-media.jpg'

import hand from '../images/hand.png'
import HighFidel1 from '../images/devPortfolio1.jpg'
import HighFidel2 from '../images/devPortfolio2.jpg'
import BCMicon from '../images/bcm-icon.jpg'
import BracDesign from '../images/bracSite.jpg'


const Section3 = () => {

    useLayoutEffect(() => {

        const projectsUpItems = document.querySelectorAll('.projects-stag-up');

        const projectsUpOptions = {
            threshold: .2,
            rootMargin: '0px -100px'
        };

        const projectsUpOnScroll = new IntersectionObserver(function(entries, projectsUpOnScroll) {
            entries.forEach(entry => {
                if(!entry.isIntersecting) {
                    return;
                } else {
                    entry.target.classList.add('projects-animate-up');
                    projectsUpOnScroll.unobserve(entry.target);
                }
            })
        }, projectsUpOptions);

        projectsUpItems.forEach(projectsUpItem => {
            projectsUpOnScroll.observe(projectsUpItem);
        })


        ///// SECOND OBSERVER /////

        const projectsLeftItem = document.querySelector('.projects-header');

        const projectsLeftOptions = {
            threshold: .2,
            rootMargin: '0px -100px'
        };

        const projectsLeftOnScroll = new IntersectionObserver(function(entries, projectsLeftOnScroll) {
            entries.forEach(entry => {
                if(!entry.isIntersecting) {
                    return;
                } else {
                    entry.target.classList.add('projects-animate-left');
                    projectsLeftOnScroll.unobserve(entry.target);
                }
            })
        }, projectsLeftOptions);

        projectsLeftOnScroll.observe(projectsLeftItem);
});


// const history = useHistory();

// const routeChange = () =>{ 
//   let path = `newPath`; 
//   history.push(path);
// }


    return (
        <div id='section3' className="section3-container">
            <div className="yellowLine2"></div>
            <div className='white-bgBar1'></div>

                {/* COME BACK AND ADD A MAP!!!!! */}
                     {/* WHITE BACKGROUND */}
            <div className='white-background'>
                <div className='white-background__border-line'></div>
                <div className='white-background__paper-line1'></div>
                <div className='white-background__paper-line2'></div>
                <div className='white-background__paper-line3'></div>
                <div className='white-background__paper-line4'></div>
                <div className='white-background__paper-line5'></div>
                <div className='white-background__paper-line6'></div>
            </div>
                    {/* COME BACK AND ADD A MAP!!!!! */}


                     {/* BLACK BACKGROUND */}
            <div className="black-background"></div>

            <h1 className='projects-header'>projects</h1>


                      {/* PROJECT 1 */}
            <div className='project-container1 projects-stag-up'>
                {/* <h4 className="project-container1__dev-pro">developer profolio</h4> */}
                <div className="project-container1__box1">
                    <img src={Portfolio} alt="Dev-Portfolio" className='project-container1__box1__devPortfolio'/>
                </div>

                <h4 className="project-container1__project-title1"><nobr>dev profolio</nobr></h4>
                <h4 className="project-container1__project-title2"><nobr>my role</nobr></h4>
                <h4 className="project-container1__fullyResponsive"><nobr>fully responsive design!</nobr></h4>
                <p className="project-container1__project-text1">Ceate React App was a definite <span className='project-container1__project-text1__overkill'>overkill</span> for this portfolio project. A Server Side Rendering library like Gatsby.js would have worked best. Needing to freshen up on my React development skills after working in other libraries/frameworks prompted my decision.</p>
                <div className='project-container1__project-text-line'></div>
                <p className="project-container1__project-text2">Responsibilites included sketch ideation, low and high fidelity designs, photo editing, JSX and CSS styling, very little JavaScript besides fashioning React components, along with submiting to Github repository and branches.</p>
                <h4 className='project-container1__tool-stack'><nobr>team tools</nobr></h4>
                <h5 className='project-container1__tool-stack__my-role'><nobr>my role in blue...</nobr></h5>
                <ul className="project-container1__tool-stack__list">
                    <li className='project-container1__tool-stack__list__item--1'><span className='blue-color'>Figma</span></li>
                    <li className='project-container1__tool-stack__list__item--2'><span className='blue-color'>Photoshop/Pixlr</span></li>
                    <li className='project-container1__tool-stack__list__item--3'><span className='blue-color'>HTML</span></li>
                    <li className='project-container1__tool-stack__list__item--4'><span className='blue-color'>CSS/SASS</span></li>
                    <li className='project-container1__tool-stack__list__item--5'>JavaScript</li>
                    <li className='project-container1__tool-stack__list__item--6'><span className='blue-color'>React<span className='red-color'> overkill!</span></span></li>
                    <li className='project-container1__tool-stack__list__item--7'><span className='blue-color'>Git/Github</span></li>
                    <li className='project-container1__tool-stack__list__item--8'>NPM</li>
                </ul>
                <div className='project-container1__breakLine1'></div>
                <h2 className='project-container1__lineBreak'>&#60;<span className='br'>br</span>&#62;</h2>
                <div className='project-container1__breakLine2'></div>
            </div>


                      {/* PROJECT 2 */}
            <div className='project-container2 projects-stag-up'>
                <h4 className="project-container2__before">before</h4>
                <div className="project-container2__box1">
                    <a href="https://www.bronxriverart.org/">
                        <img src={OldBrac} alt="Old-Brac" className='project-container2__box1__oldBrac'/>
                    </a>
                </div>

                <div className='project-container2__equalLine1'></div> 
                <div className='project-container2__equalLine2'></div> 

                <h4 className="project-container2__after">after</h4>
                <div className="project-container2__box2">
                    <a href="https://reverent-banach-13541c.netlify.app">
                        <img src={NewBrac} alt="Old-Brac" className='project-container2__box2__newBrac'/>
                    </a>
                </div>
                <h4 className="project-container2__project-title1">brac</h4>
                {/* <h4 className="project-container2__fullyResponsive">fully responsive design!</h4> */}
                <h4 className="project-container2__project-title2"><nobr>my role</nobr></h4>
                <p className="project-container2__project-text1">Bronx River Art Center (BRAC) is a culturally diverse, multi-arts, non-profit organization that provides a forum for artists and youth to transform creativity into vision. The original website, built in 1999. The new version is meant to be easily and enjoyably navigated by its target users including log-in/log-out features.</p>
                <div className='project-container2__project-text-line'></div>
                <p className="project-container2__project-text2">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet deleniti nemo temporibus, animi veniam dolorum! Enim praesentium sit ducimus nisi placeat reprehenderit facilis ad ipsa aspernatur, distinctio reiciendis esse natus dolorem suscipit quo sed? Corporis, nesciunt!</p>
                <h4 className='project-container2__tool-stack'><nobr>team tools</nobr></h4>
                <h5 className='project-container2__tool-stack__my-role'><nobr>my role in blue...</nobr></h5>
                <ul className="project-container2__tool-stack__list">
                    <li className='project-container2__tool-stack__list__item--1'><span className='blue-color'>Figma</span></li>
                    <li className='project-container2__tool-stack__list__item--2'><span className='blue-color'>Photoshop/Pixlr</span></li>
                    <li className='project-container2__tool-stack__list__item--3'><span className='blue-color'>HTML</span></li>
                    <li className='project-container2__tool-stack__list__item--4'><span className='blue-color'>CSS/SASS</span></li>
                    <li className='project-container2__tool-stack__list__item--5'>JavaScript</li>
                    <li className='project-container2__tool-stack__list__item--6'><span className='blue-color'>Gatsby.js</span></li>
                    <li className='project-container2__tool-stack__list__item--7'><span className='blue-color'>Git/Github</span></li>
                    <li className='project-container2__tool-stack__list__item--8'>NPM</li>
                    <li className='project-container2__tool-stack__list__item--9'>Auth0</li>
                </ul>


                <img className='project-container2__hand' src={hand}  alt="order hand" />
               
                <Link className='project-container2__brac-study' to='/brac-case-study'>
                    <button className='project-container2__brac-study__btn'>
                            View BRAC Case Study
                    </button>
                    <div className='project-container2__brac-study__btn__line'></div>
                </Link>


                <div className='project-container2__breakLine1'></div>
                <h2 className='project-container2__lineBreak'>&#60;<span className='br'>br</span>&#62;</h2>
                <div className='project-container2__breakLine2'></div>
            </div>


                     {/* PROJECT 3 */}
            <div className='project-container3 projects-stag-up'>
                <h4 className="project-container3__before">before</h4>
                <div className="project-container3__box1">
                    <a href="https://www.loc8nearme.com/new-york/bronx/dominicks/3503223/">
                        <img src={OldDominicks} alt="Old-Dominicks" className='project-container3__box1__oldDominicks'/>
                    </a>
                </div>

                <div className='project-container3__equalLine1'></div> 
                <div className='project-container3__equalLine2'></div> 

                <h4 className="project-container3__after">after</h4>
                <div className="project-container3__box2">
                    <a href="https://distracted-babbage-6da032.netlify.app">
                        <img src={NewDominicks} alt="Old-Dominicks" className='project-container3__box2__newDominicks'/>
                    </a>
                </div>
                <h4 className="project-container3__project-title1">dominick's</h4>
                {/* <h4 className="project-container3__fullyResponsive">fully responsive design!</h4> */}
                <h4 className="project-container3__project-title2"><nobr>my role</nobr></h4>
                <p className="project-container3__project-text1">Unfussy, decades-old Italian eatery serving up family-style pastas and entrees to communal tables. This project was built in light of the pandemic to capture the energy of the restaurant for the web so that potential customers can order online or learn more about which entrees Dominick's has to offer.</p>
                <div className='project-container3__project-text-line'></div>
                <p className="project-container3__project-text2">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet deleniti nemo temporibus, animi veniam dolorum! Enim praesentium sit ducimus nisi placeat reprehenderit facilis ad ipsa aspernatur, distinctio reiciendis esse natus dolorem suscipit quo sed? Corporis, nesciunt!</p>
                <h4 className='project-container3__tool-stack'><nobr>team tools</nobr></h4>
                <h5 className='project-container3__tool-stack__my-role'><nobr>my role in blue...</nobr></h5>
                <ul className="project-container3__tool-stack__list">
                    <li className='project-container3__tool-stack__list__item--1'><span className='blue-color'>Figma</span></li>
                    <li className='project-container3__tool-stack__list__item--2'><span className='blue-color'>Photoshop/Pixlr</span></li>
                    <li className='project-container3__tool-stack__list__item--3'>HTML</li>
                    <li className='project-container3__tool-stack__list__item--4'><span className='blue-color'>CSS/SASS</span></li>
                    <li className='project-container3__tool-stack__list__item--5'>JavaScript</li>
                    <li className='project-container3__tool-stack__list__item--6'>Gatsby</li>
                    <li className='project-container3__tool-stack__list__item--7'>GraphQL</li>
                    <li className='project-container3__tool-stack__list__item--8'>Git/Github</li>
                    <li className='project-container3__tool-stack__list__item--9'>NPM</li>
                </ul>
                <div className='project-container3__breakLine1'></div>
                <h2 className='project-container3__lineBreak'>&#60;<span className='br'>br</span>&#62;</h2>
                <div className='project-container3__breakLine2'></div>
            </div>


                       {/* PROJECT 4 */}
             <div className='project-container4 projects-stag-up'>
                <h4 className="project-container4__before">before</h4>
                <div className="project-container4__box1">
                    <img src={OldBCM} alt="Old-BCM" className='project-container4__box1__oldBCM'/>
                </div>

                <div className='project-container4__equalLine1'></div> 
                <div className='project-container4__equalLine2'></div> 

                <h4 className="project-container4__after">after</h4>
                <div className="project-container4__box2">
                    <img src={NewBCM} alt="New-BCM" className='project-container4__box2__newBCM'/>
                </div>
                <h4 className="project-container4__project-title1"><nobr>bcm media</nobr></h4>
                <h4 className="project-container4__fullyResponsive"><nobr>fully responsive design!</nobr></h4>
                <h4 className="project-container4__project-title2"><nobr>my role</nobr></h4>
                <p className="project-container4__project-text1">BCM Media, an advertising firm in Darien, CT, provides strategic media planning and buying services. The introductory version of the website was text heavy. The updated version focuses on small easily consumable paragraphs and plenty of images while retaining the original branding of the business</p>
                <div className='project-container4__project-text-line'></div>
                <p className="project-container4__project-text2">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet deleniti nemo temporibus, animi veniam dolorum! Enim praesentium sit ducimus nisi placeat reprehenderit facilis ad ipsa aspernatur, distinctio reiciendis esse natus dolorem suscipit quo sed? Corporis, nesciunt!</p>
                <h4 className='project-container4__tool-stack'><nobr>team tools</nobr></h4>
                <h5 className='project-container4__tool-stack__my-role'><nobr>my role in blue...</nobr></h5>
                <ul className="project-container4__tool-stack__list">
                    <li className='project-container4__tool-stack__list__item--1'><span className='blue-color'>Figma</span></li>
                    <li className='project-container4__tool-stack__list__item--2'><span className='blue-color'>Photoshop/Pixlr</span></li>
                    <li className='project-container4__tool-stack__list__item--3'>CSS</li>
                    <li className='project-container4__tool-stack__list__item--4'>WordPress</li>
                    <li className='project-container4__tool-stack__list__item--5'><nobr>WAMP Server</nobr></li>
                </ul>
                <div className='project-container4__breakLine1'></div>
                <h2 className='project-container4__lineBreak'>&#60;<span className='br'>br</span>&#62;</h2>
                <div className='project-container4__breakLine2'></div>
            </div>

                               {/* PROJECT 5 */}
            <div className='project-container5 projects-stag-up'>
                {/* <h4 className="project-container5__after">after</h4> */}
                <div className="project-container5__box1">
                    <img src={HighFidel1} alt="My-Site" className='project-container5__box1__HighFidel1'/>
                </div>
                <div className="project-container5__box2">
                    <img src={HighFidel2} alt="Dev-Site" className='project-container5__box2__HighFidel2'/>
                </div>
                <div className="project-container5__box3">
                    <img src={BCMicon} alt="BCM-icon" className='project-container5__box3__BCMicon'/>
                </div>
                <div className="project-container5__box4">
                    <img src={BracDesign} alt="Brac-Site" className='project-container5__box4__BracDesign'/>
                </div>
                <h4 className="project-container5__project-title1">figma</h4>
                <h4 className="project-container5__high-fidelity"><nobr>high fidelity design!</nobr></h4>
                <p className="project-container5__project-text1">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet deleniti nemo temporibus, animi veniam dolorum! Enim praesentium sit ducimus nisi placeat reprehenderit facilis ad ipsa aspernatur, distinctio reiciendis esse natus dolorem suscipit quo sed? Corporis, nesciunt!</p>
                <h4 className='project-container5__tool-stack5'><nobr className='blue-color'>team tools</nobr></h4>
                <h5 className='project-container5__tool-stack5__my-role5'><nobr>my role in blue...</nobr></h5>
                <ul className="project-container5__tool-stack5__list">
                    <li className='project-container5__tool-stack5__list__item--1'><span className='blue-color'>Figma</span></li>
                    <li className='project-container5__tool-stack5__list__item--2'><span className='blue-color'>Photoshop/Pixlr</span></li>
                </ul>
            </div>
        </div>
    )
}

export default Section3
