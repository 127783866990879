import React, { useLayoutEffect, useState } from 'react'
import '../components/contactForm.scss'
import { useForm } from 'react-hook-form'

import{ init, sendForm } from 'emailjs-com'
init("user_PpzYQPywD19L0ULiARmTi")


const Contact = () => {

    useLayoutEffect(() => {

        const contactUpItems = document.querySelectorAll('.contact-stag-up');

        const contactUpOptions = {
            threshold: .2,
            rootMargin: '0px -100px'
        };

        const contactUpOnScroll = new IntersectionObserver(function(entries, contactUpOnScroll) {
            entries.forEach(entry => {
                if(!entry.isIntersecting) {
                    return;
                } else {
                    entry.target.classList.add('contact-animate-up');
                    contactUpOnScroll.unobserve(entry.target);
                }
            })
        }, contactUpOptions);

        contactUpItems.forEach(contactUpItem => {
            contactUpOnScroll.observe(contactUpItem);
        })

        ///// SECOND OBSERVER /////

        const contactLeftItem = document.querySelector('.header-contact');

        const contactLeftOptions = {
            threshold: .2,
            rootMargin: '0px -100px'
        };

        const contactLeftOnScroll = new IntersectionObserver(function(entries, contactLeftOnScroll) {
            entries.forEach(entry => {
                if(!entry.isIntersecting) {
                    return;
                } else {
                    entry.target.classList.add('contact-animate-left');
                    contactLeftOnScroll.unobserve(entry.target);
                }
            })
        }, contactLeftOptions);

            contactLeftOnScroll.observe(contactLeftItem);


        ///// THIRD OBSERVER /////


        const contactRightItems = document.querySelectorAll('.contact-stag-right');

        const contactRightOptions = {
            threshold: .2,
            rootMargin: '0px -100px'
        };

        const contactRightOnScroll = new IntersectionObserver(function(entries, contactRightOnScroll) {
            entries.forEach(entry => {
                if(!entry.isIntersecting) {
                    return;
                } else {
                    entry.target.classList.add('contact-animate-right');
                    contactRightOnScroll.unobserve(entry.target);
                }
            })
        }, contactRightOptions);

        contactRightItems.forEach(contactRightItem => {
            contactRightOnScroll.observe(contactRightItem);
        })

});

/// REACT-HOOK-FORM CODE
const { register, formState: { errors }, handleSubmit, watch } = useForm()
const onSubmit = data => {
    generateContactNumber()
    const statusMessage = document.querySelector('.status-message')
    const form = document.querySelector('#contact-form')

    sendForm('contact_form', 'template_3i3tf5b', '#contact-form')
        .then(function(response) {
            setStatusMessage('Message sent!')
            statusMessage.className = 'status-message success'
            setTimeout(()=> {
                statusMessage.className = 'status-message'
            }, 5000)
            form.reset()
        }, function(error) {
            setStatusMessage('Failed to send message! Please try again later.')
            statusMessage.className = 'staus-message failure'
            setTimeout (()=> {
                statusMessage.className = 'status-message'
            }, 5000)
        })
}


const message = watch('message') || ''
const messageCharsLeft = 1200 - message.length


/// EMAILJS CODE
const [statusMessage, setStatusMessage] = useState('Message')

const [contactNumber, setContactNumber] = useState('000000')

const generateContactNumber = () => {
    const numStr = '000000' + (Math.random() * 1000000 | 0)
    setContactNumber(numStr.substring(numStr.length - 6))
}

    return(
        <form id='contact-form' action='#' className='contact-container contact-stag-up' onSubmit={handleSubmit(onSubmit)}>
            <input type='hidden' name='contact_number' value={contactNumber} />
            <p className='status-message'>{statusMessage}</p>

            <div className='white-line1 contact-stag-right'></div>
            <div className='white-line2 contact-stag-right'></div>
            <p className='email-instruction'>Send an Email. All fields with an asterisk (<abbr title='required' aria-label='required' className='required'>* </abbr>) are required</p>
            
            <h1 className='header-contact'>contact me</h1>
            <label for='name' className='name'>name<abbr title='required' aria-label='required' className='required'>*</abbr></label>
            <input 
                type='text' 
                name='name' 
                id='name' 
                className='input1' 
                {...register('name', {required: true, maxLength: 50})}
            />
            {errors.name && (<span className='error__name'>'Name is invalid'</span>)}

            <label for='email' className='email'>email<abbr title='required' aria-label='required' className='required'>*</abbr></label>
            <input 
                type='email' 
                name='email' 
                id='email' 
                className='input2' 
                {...register('email', {required: true})}
            />
            {errors.email && (<span className='error__email'>'Email is invalid'</span>)}

            <label for='subject' className='subject'>subject<abbr title='required' aria-label='required' className='required'>*</abbr></label>
            <input 
                type='text' 
                name='subject' 
                id='subject' 
                className='input3' 
                {...register('subject', {required: true, maxLength: 100})}
            />
            {errors.subject && (<span className='error__subject'>'Must be less than 100 characters'</span>)}

            <label for='message' className='message'>message<abbr title='required' aria-label='required' className='required'>*</abbr></label>
            <textarea 
                name='message' 
                id='message' 
                className='input4' 
                row='5' 
                {...register('message', {required: true, maxLength: 1200})}>
            </textarea>
            {errors.message && (<span className='error__message'>'Must be less than 1200 characters'</span>)}
            <div className='chars-box'>
                <p className='chars-box-count'>{messageCharsLeft}</p>
            </div>

            <input 
                type='submit' 
                className='submitBTN' 
                value='send email' 
            />
        </form>
    )
}

export default Contact
