import React, { useState } from 'react'
import '../components/section1.scss'

import ReactTypingEffect from 'react-typing-effect'

import DevPic from '../images/webDevPic4.png'
import CutOut1 from '../images/cut-out1Funky.jpg'
import CutOut2 from '../images/cut-out2Funky.jpg'
import CutOut3 from '../images/cut-out3Funky.jpg'
import CutOut4 from '../images/cut-out4Funky.jpg'


const Section1 = () => {

    const [blackBar1, setBlackBar1] = useState(true)
    const [blackBar2, setBlackBar2] = useState(true)
    const [blackBar3, setBlackBar3] = useState(true)
    const [blackBar4, setBlackBar4] = useState(true)
    const [funkyImg1, setFunkyImg1] = useState(true)
    const [funkyImg2, setFunkyImg2] = useState(true)
    const [funkyImg3, setFunkyImg3] = useState(true)
    const [funkyImg4, setFunkyImg4] = useState(true)
    const [headerTitle, setHeaderTitle] = useState(true)
    const [wimpyDev, setWimpyDev] = useState(true)

    const sec1AnimationScroll = () => {
        if(window.scrollY <= 966) {
            setBlackBar1(true)
            setBlackBar2(true)
            setBlackBar3(true)
            setBlackBar4(true)
            setFunkyImg1(true)
            setFunkyImg2(true)
            setFunkyImg3(true)
            setFunkyImg4(true)
            setHeaderTitle(true)
            setWimpyDev(true)

            window.removeEventListener('scroll', sec1AnimationScroll)
        } else {
            setBlackBar1(false)
            setBlackBar2(false)
            setBlackBar3(false)
            setBlackBar4(false)
            setFunkyImg1(false)
            setFunkyImg2(false)
            setFunkyImg3(false)
            setFunkyImg4(false)
            setWimpyDev(false)
        }
    }

    window.addEventListener('scroll', sec1AnimationScroll)

    return (
        <div id='section1' className='section1-container'>
            <div className='bgShine'></div>

                        {/* BLACK BG BARS */}
            <div className={blackBar1 ? 'black-bgBar1 black-bgBar1__active' : 'black-bgBar1'}></div>
            <div className={blackBar2 ? 'black-bgBar2 black-bgBar2__active' : 'black-bgBar2'}></div>

            <div className={blackBar3 ? 'black-bgBar3 black-bgBar3__active' : 'black-bgBar3'}>
                {/* HEADER TITLE */}
                <div className={headerTitle ? 'header-title header-title__active' : 'header-title'}>

                    <h1 className="header-title__diary">diary</h1>
                    <h4 className="header-title__of-a">of a</h4>

                        <ReactTypingEffect 
                            className='header-title__typewriter'
                            text={['web dev']}
                            speed={150}
                            eraseSpeed={150}
                            typingDelay={1500}
                            cursorRenderer={cursor => <h1 className="header-title__line">{cursor}</h1>}
                            displayTextRenderer={(text) => {
                                return (
                                    <div className='header-title__flex'>
                                    <span className='header-title__curly-brace1'>&#123;</span>
                                    <h1 className="header-title__web-dev">
                                        {text}
                                    </h1>
                                    <span className='header-title__curly-brace2'>&#125;</span>
                                    </div>
                                )
                            }}
                        />
                </div>
            </div>

            <div className={blackBar4 ? 'black-bgBar4 black-bgBar4__active' : 'black-bgBar4'}></div>

                        {/* WHITE BG BARS */}
            <div className='white-bgBar1'></div>
            <div className='white-bgBar2'>
                <div className='white-bgBar2__paper-line1'></div>
            </div>
            <div className='white-bgBar3'></div>
            <div className='white-bgBar4'>
                <div className='white-bgBar4__paper-line1'></div>
                <div className='white-bgBar4__paper-line2'></div>
                <div className='white-bgBar4__paper-line3'></div>
                <div className='white-bgBar4__paper-line4'></div>
            </div>
            <div className='white-bgBar5'>
                <div className='white-bgBar5__paper-line1'></div>
            </div>
            <div className='white-bgBar6'></div>
            <div className='white-bgBar7'></div>
            <div className='white-bgBar8'></div>
            <div className='white-bgBar9'></div>


                           {/* PICS */}
            <div className={ wimpyDev ? 'devPic-container devPic-container__active' : 'devPic-container'}>
                <img src={DevPic} alt="Wimpy Dev" className='devPic-container__wimpy-dev'/>
            </div>

            <div className='pic-grid'>
                <div className={funkyImg1 ? 'pic-grid__funkyContainer1 pic-grid__funkyContainer1__active' : 'pic-grid__funkyContainer1'}>
                    <img src={CutOut1} alt="image1" className='pic-grid__funkyContainer1__funkyImg1'/>
                </div>
                <div className={funkyImg2 ? 'pic-grid__funkyContainer2 pic-grid__funkyContainer2__active' : 'pic-grid__funkyContainer2'}>
                    <img src={CutOut2} alt="image2" className='pic-grid__funkyContainer2__funkyImg2'/>
                </div>
                <div className={funkyImg3 ? 'pic-grid__funkyContainer3 pic-grid__funkyContainer3__active' : 'pic-grid__funkyContainer3'}>
                    <img src={CutOut3} alt="image3" className='pic-grid__funkyContainer3__funkyImg3'/>
                </div>
                <div className={funkyImg4 ? 'pic-grid__funkyContainer4 pic-grid__funkyContainer4__active' : 'pic-grid__funkyContainer4'}>
                    <img src={CutOut4} alt="image4" className='pic-grid__funkyContainer4__funkyImg4'/>
                </div>
            </div>
        </div>
    )
}

export default Section1
