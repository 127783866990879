import React, { useLayoutEffect, useState } from 'react'
import { Link } from 'react-scroll'

import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'

import '../components/section2.scss'

import LinkedIn from '../images/LinkedIn-Logo.wine 1.png'
import myPic from '../images/aboutPicResized2.jpg'
import AboutPic1 from '../images/ToolsResized.jpg'
import AboutPic2 from '../images/craftingPicCroped.jpg'
import AboutPic3 from '../images/TabletParallaxResized.jpg'
import AboutPic4 from '../images/manProgrammingResized.jpg'

import Tools from '../images/Tools.jpg'
import Crafting from '../images/craftingPicLg.jpg'
import Tablet from '../images/TabletParallaxLg.jpg'
import Dev from '../images/manProgramming.jpg'


const Section2 = (props) => {


    useLayoutEffect(() => {

        ///// THE FIRST OBSERVER /////
        //////////////////////////////

        const aboutLeftItems = document.querySelectorAll('.about-stag-left');

        const aboutLeftOptions = {
            threshold: .5
        };

        const aboutLeftOnScroll = new IntersectionObserver(function(entries, aboutLeftOnScroll) {
            entries.forEach(entry => {
                if(!entry.isIntersecting) {
                    return;
                } else {
                    entry.target.classList.add('about-animate-left');
                    aboutLeftOnScroll.unobserve(entry.target);
                }
            })
        }, aboutLeftOptions);

        aboutLeftItems.forEach(aboutLeftItem => {
            aboutLeftOnScroll.observe(aboutLeftItem);
        })


        ///// THE SECOND OBSERVER /////
        //////////////////////////////


        const aboutLeft2Items = document.querySelectorAll('.about-stag-left2');

        const aboutLeft2Options = {
            threshold: .5
        };

        const aboutLeft2OnScroll = new IntersectionObserver(function(entries, aboutLeft2OnScroll) {
            entries.forEach(entry => {
                if(!entry.isIntersecting) {
                    return;
                } else {
                    entry.target.classList.add('about-animate-left2');
                    aboutLeft2OnScroll.unobserve(entry.target);
                }
            })
        }, aboutLeft2Options);

        aboutLeft2Items.forEach(aboutLeft2Item => {
            aboutLeft2OnScroll.observe(aboutLeft2Item);
        })



            ///// THE THIRD OBSERVER /////
            //////////////////////////////


        const aboutUpItems = document.querySelectorAll('.about-stag-up');

        const aboutUpOptions = {
            threshold: .5
        };

        const aboutUpOnScroll = new IntersectionObserver(function(entries, aboutUpOnScroll) {
            entries.forEach(entry => {
                if(!entry.isIntersecting) {
                    return;
                } else {
                    entry.target.classList.add('about-animate-up');
                    aboutUpOnScroll.unobserve(entry.target);
                }
            })
        }, aboutUpOptions);

        aboutUpItems.forEach(aboutUpItem => {
            aboutUpOnScroll.observe(aboutUpItem);
        })
});


        /////// State for showing large size images
        const [open1, setOpen1] = useState(false);
        const [open2, setOpen2] = useState(false);
        const [open3, setOpen3] = useState(false);
        const [open4, setOpen4] = useState(false);



        const onOpenModal1 = () => {
            setOpen1(true)
            setImgWasClicked(true)
        }

        const onOpenModal2 = () => {
            setOpen2(true)
            setImgWasClicked(true)
        }

        const onOpenModal3 = () => {
            setOpen3(true)
            setImgWasClicked(true)
        }

        const onOpenModal4 = () => {
            setOpen4(true)
            setImgWasClicked(true)
        }

        
        const onCloseModal1 = () => {
            setOpen1(false)
            setImgWasClicked(false)
        }
        const onCloseModal2 = () => {
            setOpen2(false)
            setImgWasClicked(false)
        }
        const onCloseModal3 = () => {
            setOpen3(false)
            setImgWasClicked(false)
        }
        const onCloseModal4 = () => {
            setOpen4(false)
            setImgWasClicked(false)
        }


        //////// PULLING PROPS FROM APP.JS
        const {setImgWasClicked} = props


        const closeIcon = (
            <svg className='closeIcon' viewBox="0 0 34 34" fill="#fff" width={28} height={28}>
                <path d="M17 14.0301L4.48495 1.51507C3.66485 0.694969 2.3352 0.694969 1.5151 1.51507C0.694999 2.33517 0.694999 3.66482 1.5151 4.48492L14.0302 17L1.5151 29.5151C0.694999 30.3352 0.694999 31.6648 1.5151 32.4849C2.3352 33.305 3.66485 33.305 4.48495 32.4849L17 19.9698L29.5151 32.4849C30.3352 33.305 31.6648 33.305 32.4849 32.4849C33.305 31.6648 33.305 30.3352 32.4849 29.5151L19.9699 17L32.4849 4.48492C33.305 3.66482 33.305 2.33517 32.4849 1.51507C31.6648 0.694969 30.3352 0.694969 29.5151 1.51507L17 14.0301Z"/>
            </svg>
          );


    
    return (
        <div id='section2' className='section2-container'>

            <div className="yellowLine"></div>

                            {/* WHITE BG BARS */}
            <div className="white-bgBar1"></div>
            <div className="white-bgBar2">
                <div className='white-bgBar2__paper-line1'></div>
                <div className='white-bgBar2__paper-line2'></div>
                <div className='white-bgBar2__paper-line3'></div>
            </div>
            <div className="white-bgBar3"></div>
            <div className="white-bgBar4">
                <div className='white-bgBar4__paper-line1'></div>
                <div className='white-bgBar4__paper-line2'></div>
                <div className='white-bgBar4__paper-line3'></div>
            </div>

                             {/* GRAY SQUARE */}
            <div className="graySquare"></div>
            
                             {/* WHITE GRID BARS */}
            <h1 className="about-me about-stag-left">about me</h1>
            
            <div className='white-gridBars'>
                <div className="white-gridBar about-stag-left2"></div>
                <div className="white-gridBar about-stag-left2"></div>
                <div className="white-gridBar about-stag-left2"></div>
                <div className="white-gridBar about-stag-left2"></div>
                <div className="white-gridBar about-stag-left2"></div>
                <div className="white-gridBar about-stag-left2"></div>
                <div className="white-gridBar about-stag-left2"></div>
                <div className="white-gridBar about-stag-left2"></div>
                <div className="white-gridBar about-stag-left2"></div>
                <div className="white-gridBar about-stag-left2"></div>
                <div className="white-gridBar about-stag-left2"></div>
            </div> 
                                {/* LINKIN LOGO */}
            <a href="https://www.linkedin.com/in/adam-riles-851145199/" className='linkedInLink'><img src={LinkedIn} alt={LinkedIn} className='linkedInLogo'/></a>

            <div className='myPic about-stag-left'>
                <div className='myPic-container'>
                    <img src={myPic} alt="Adam Riles" className='myPic-container__image'/>
                </div>
            </div>
                                 {/* DEV TITLES & PARAGRAPH */}
            <div className='aboutMe-grid'>
                <h2 className='adamRiles about-stag-up'>adam riles</h2>
                <h3 className='dev-title about-stag-up'><nobr> <span className='UI'>UI</span> <span className='UX'>UX</span> Designer / developer </nobr>
                </h3>
                <p className='steveJobs-quote about-stag-up'>“Design is not just what it looks like and feels like. Design is how it works.” — Steve Jobs</p>
                <p className="aboutMe-text about-stag-up">
                /* I love to create prototypes that focus on ease of navigation and a clear presentation of content. The most important aspect of all design -  no matter what the object -  is empathy. My goal is to empathize with target users and present the solution to their problems.
                */</p>
            </div>

                                {/* ABOUT ME PICS */}
            <div className="aboutPics-grid about-stag-up">
                <div className='aboutPics-grid__container1'>
                    <Link
                        activeClass='active'
                        to='section2'
                        spy={true}
                        smooth={true}
                        offset={0}
                        duration={500}>
                        <img src={AboutPic1} alt="image1" onClick={onOpenModal1}className='aboutPics-grid__container1__aboutPic1'/>
                    </Link>
                </div>
                <div className='aboutPics-grid__container2'>
                    <Link
                        activeClass='active'
                        to='section2'
                        spy={true}
                        smooth={true}
                        offset={0}
                        duration={500}>
                        <img src={AboutPic2} alt="image2" onClick={onOpenModal2} className='aboutPics-grid__container2__aboutPic2'/>
                    </Link>
                </div>
                <div className='aboutPics-grid__container3'>
                    <Link
                        activeClass='active'
                        to='section2'
                        spy={true}
                        smooth={true}
                        offset={0}
                        duration={500}>
                        <img src={AboutPic3} alt="image3" onClick={onOpenModal3} className='aboutPics-grid__container3__aboutPic3'/>
                    </Link>
                </div>
                <div className='aboutPics-grid__container4'>
                    <Link
                        activeClass='active'
                        to='section2'
                        spy={true}
                        smooth={true}
                        offset={0}
                        duration={500}>
                        <img src={AboutPic4} alt="image4" onClick={onOpenModal4} className='aboutPics-grid__container4__aboutPic4'/>
                    </Link>
                </div>
            </div>

            <Modal open={open1} onClose={onCloseModal1} blockScroll={false}
            closeIcon={closeIcon}
            classNames={{
                        overlay: 'customOverlay',
                        modal: 'customModal'
                      }} center>
                <img src={Tools} alt="Dev" className='modal-pic'/>
            </Modal>

            <Modal open={open2} onClose={onCloseModal2} blockScroll={false}
            closeIcon={closeIcon}
            classNames={{
                        overlay: 'customOverlay',
                        modal: 'customModal'
                      }} center>
                <img src={Crafting} alt="Dev" className='modal-pic'/>
            </Modal>

            <Modal open={open3} onClose={onCloseModal3} blockScroll={false}
            closeIcon={closeIcon}
            classNames={{
                        overlay: 'customOverlay',
                        modal: 'customModal'
                      }} center>
                <img src={Tablet} alt="Dev" className='modal-pic'/>
            </Modal>

            <Modal open={open4} onClose={onCloseModal4} blockScroll={false}
            closeIcon={closeIcon}
            classNames={{
                        overlay: 'customOverlay',
                        modal: 'customModal'
                      }} center>
                <img src={Dev} alt="Dev" className='modal-pic'/>
            </Modal>

        </div>
    )
}

export default Section2
