import React from 'react'
import '../components/footer.scss'


const Footer = () => {
    return(
        <footer className="footer">
            <p className='footer__text'>
                copyright &#169; all rights reserved design by adam riles
            </p>
        </footer>
    )
}

export default Footer

