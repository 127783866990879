import React from 'react'
import './bracCaseStudy.scss'

import CaseStudyNav from './caseStudyNav'
import Footer from './footer'
// import SideSlideNav from './sideSlideNav'





const BracCaseStudy = () => {




    return (
        <div className='case-study-container'>
        <CaseStudyNav />
          <h1 className='header'>BRAC Case Study</h1>

          <Footer />
        </div>
    )
}

export default BracCaseStudy
