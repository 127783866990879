import React, { useState, useEffect } from 'react'
import '../App.scss'
import '../styles/typography.scss'
import { useMediaQuery } from '@material-ui/core'


import Navbar from './navbar'
import Section1 from './section1'
import Section2 from './section2'
import Section3 from './section3'
import Section4 from './section4'
import Footer from './footer'
import SideSlideNav from './sideSlideNav'


///// CONTEXT TO OPEN AND CLOSE SLIDE NAVIGATION /////
export const NavContext = React.createContext()




const Home = () => {
  ///// MEDIA QUERY FOR NAV /////
  const hideNav = useMediaQuery('(max-width: 1001.324px)')

  ///// RENDER SLIDE NAV /////
  const renderSlideNav = () => {
    if (slideNav && hideNav) {
      return (
        <SideSlideNav className='slide-open slideNav__box'
                      slideNav={slideNav} setSlideNav={setSlideNav}
                      showSiteOverlay={showSiteOverlay} setShowSiteOverlay={setShowSiteOverlay} 
                      closeNav={closeNav}    
        />
      )
    } else {
      return null
    }
  }

  ///// RENDER SLIDE NAV /////
  const renderOverlay = () => {
    if (showSiteOverlay && hideNav) {
      return (
        <div id='overlay' className='openSiteOverlay'></div>
      )
    } else {
      return null
    }
  }




  ///// STATE TO OPEN/CLOSE SLIDE NAV /////
  const [slideNav, setSlideNav] = useState(false)



  ///// STATE TO OPEN/CLOSE OVERLAY /////
  const [showSiteOverlay, setShowSiteOverlay] = useState(false)


  ///// WAS CLICKED STATE
  const [imgWasClicked, setImgWasClicked] = useState(false)


  useEffect(() => {
      if(imgWasClicked === true) {
        document.body.style.overflowY = 'hidden'
      } else {
        document.body.style.overflowY = 'scroll'
      }
  })

  //////// TO CLOSE SLIDE NAV ////////
  const closeNav = () => {
    //REMOVES/ADDS SLIDE NAV ANIMATION CLASSES
    document.getElementById('slideNav').classList.remove('slide-open')
    document.getElementById('slideNav').classList.add('slide-away')

    //REMOVES/ADDS OVERLAY ANIMATION CLASSES
    document.getElementById('overlay').classList.remove('openSiteOverlay')
    document.getElementById('overlay').classList.add('closeSiteOverlay')

    
    //TO REMOVE OVERLAY
    setTimeout(() => setShowSiteOverlay(false), 600)

    //TO CLOSE NAV
    setTimeout(() => setSlideNav(false), 600)
    
  }
  

    return (
      <NavContext.Provider value={{slideNav, setSlideNav, showSiteOverlay, setShowSiteOverlay, closeNav}}>
        <div className='home-container'>
          <Navbar  slideNav={slideNav} setSlideNav={setSlideNav}
                  showSiteOverlay={showSiteOverlay} 
                  setShowSiteOverlay={setShowSiteOverlay} 
                  closeNav={closeNav}
          />
          {renderSlideNav()}
          {renderOverlay()}
          <Section1 />
          <Section2 setImgWasClicked={setImgWasClicked} />
          <Section3 />
          <Section4 />
          <Footer />
        </div>
      </NavContext.Provider>
    )
}

export default Home
