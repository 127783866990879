import React from 'react' 
import { Link } from 'react-scroll'

import './sideSlideNav.scss'

import LinkedIn from '../images/LinkedIn-Logo.wine 1.png'
import GitHub from '../images/github-logo-white.png'


const SideSlideNav = (props) => {

    const { slideNav, setSlideNav, showSiteOverlay, setShowSiteOverlay, closeNav } = props

    const hamburgerClick = e => {
        setSlideNav(!slideNav)
        setShowSiteOverlay(!showSiteOverlay)
    }

    return (

        <div id='slideNav' className={props.className}>


        
            <Link
                activeClass='active'
                to='section1'
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                className='aTag slideNav__box__link1'
                onClick={hamburgerClick, closeNav}>
                <span className='code-text c3'>Home</span>
            </Link>
            <Link
                activeClass='active'
                to='section2'
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                className='aTag slideNav__box__link2'
                onClick={hamburgerClick, closeNav}>
                <span className='code-text c3'>About-Me</span>
            </Link>
            <Link
                activeClass='active'
                to='section3'
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                className='aTag slideNav__box__link3'
                onClick={hamburgerClick, closeNav}>
                <span className='code-text c3'>Projects</span>
            </Link>
            <Link
                activeClass='active'
                to='section4'
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                className='aTag slideNav__box__link4'
                onClick={hamburgerClick, closeNav}>
                <span className='code-text c3'>Case-Studies</span>
            </Link>
            <Link
                activeClass='active'
                to='section4'
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                className='aTag slideNav__box__link5'
                onClick={hamburgerClick, closeNav}>
                <span className='code-text c3'>Contact-Me</span>
            </Link>

            <a href= 'https://www.linkedin.com/in/adam-riles-851145199/' className='slideNav__box__link6'>
                    <img src={LinkedIn} alt='LinkedIn'className='linkedInLogo'/>
            </a>
            <a href= 'https://github.com/LordUptown/bronxRiverArt.git'
            className='slideNav__box__link7'>
                <img src={GitHub} alt='GitHub' className='gitHubLogo'/>
            </a>
        

        </div>

    );
    
};

export default SideSlideNav